'use client';
import { ToastEntity } from '@entities/toast/ui';
import { useTimer } from '@hooks/client';
import {
  NotificationsProps,
  NotificationsToastBottomSlotProps,
  NotificationsToastProps,
} from '@widgets/notifications/ui/notifications.types';
import { AnimatePresence, motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect } from 'react';
import { useMediaQuery } from 'usehooks-ts';

import { Illustration, ProgressIndicator } from '@/shared/ui';
import { Button } from '@/shared/ui/button';
import { useStore } from '@/store/context';
import { BaseNotification } from '@/store/notifications/notifications';

import { add } from '../utils/array-utils';
import styles from './notifications.module.scss';

export const Notifications = ({}: NotificationsProps) => {
  return (
    <ul className={styles['notifications']}>
      {/* <Notifications.TestButton />*/}
      <Notifications.List />
    </ul>
  );
};

Notifications.TestButton = observer(() => {
  const notificationStore = useStore()?.notifications;
  const notifications = Array.from(notificationStore?.toasts.values());

  const handleAddRandomNotification = useCallback(() => {
    const newToast = add(notifications as BaseNotification[]);
    notificationStore?.addNotification(newToast);
  }, []);

  return (
    <Button
      variant={'tertiary'}
      width={'content'}
      iconLeft={<Illustration spriteName={'icons'} name={'notes'} size={18} />}
      className="add"
      onClick={handleAddRandomNotification}
    />
  );
});

Notifications.List = observer(() => {
  const notificationStore = useStore()?.notifications;
  const notifications = Array.from(notificationStore?.toasts.values());

  const handleRemoveToast = useCallback((id: BaseNotification['id']) => {
    return notificationStore?.removeNotification(id);
  }, []);

  return (
    <AnimatePresence initial={false}>
      {Array.isArray(notifications) &&
        notifications?.length > 0 &&
        notifications?.map((toast, idx) => (
          <Notifications.Toast
            key={toast?.id}
            toast={toast}
            handleRemoveToast={handleRemoveToast}
          />
        ))}
    </AnimatePresence>
  );
});

Notifications.Toast = observer(
  ({ toast, handleRemoveToast }: NotificationsToastProps) => {
    const is1024Desktop = useMediaQuery('(min-width: 1024px)');

    const animateConfig = {
      initial: {
        opacity: 0,
        x: is1024Desktop ? -50 : 50,
      },
      animate: { opacity: 1, x: 0 },
      exit: {
        opacity: 0,
        x: is1024Desktop ? -50 : 50,
        transition: {
          duration: 0.09,
        },
      },
    };

    return (
      <motion.li
        key={toast.id}
        id={`${toast.id}`}
        initial={animateConfig.initial}
        animate={animateConfig.animate}
        exit={animateConfig.exit}
        className={styles['item']}
        layout
      >
        <ToastEntity
          title={toast.title}
          subtitle={toast.subtitle}
          handleClose={() => handleRemoveToast(toast?.id)}
          variant={toast.variant}
          bottomSlot={
            <Notifications.ToastBottomSlot
              handleRemoveToast={handleRemoveToast}
              toast={toast}
            />
          }
        />
      </motion.li>
    );
  },
);

Notifications.ToastBottomSlot = observer(
  ({ toast, handleRemoveToast }: NotificationsToastBottomSlotProps) => {
    const { count } = useTimer({
      duration: toast.delay,
    });

    useEffect(() => {
      count <= 0 && handleRemoveToast(toast.id);
    }, [count]);

    if (!toast.variant) return null;

    if (toast.variant === 'discount') {
      return (
        <Button
          width={'full-w'}
          variant={'secondary'}
          onClick={() => {}}
          text={'Go to category'}
        />
      );
    }

    if (toast.variant === 'password') {
      return (
        <Button
          width={'full-w'}
          variant={'primary'}
          onClick={() => {}}
          iconLeft={
            <Illustration size={18} name={'settings'} spriteName={'icons'} />
          }
          text={'Go to settings'}
        />
      );
    }

    return (
      <ProgressIndicator
        delay={toast.delay}
        variant={toast.variant}
        fillDirection={'left'}
      />
    );
  },
);
