'use client';
import { CardLiveFeedEntity } from '@entities/desktop/cards';
import {
  ItemType,
  LiveFeedWidgetProps,
} from '@widgets/desktop/live-feed/ui/live-feed.types';
import cn from 'clsx';
import shuffle from 'lodash.shuffle';
import { useRouter } from 'next/navigation';
import { useTranslations } from 'next-intl';
import { useMemo, useState } from 'react';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useMediaQuery } from 'usehooks-ts';

import { mockedData } from '@/mocks/items';
import { GlobalItem } from '@/shared/types/common';
import { Label } from '@/shared/ui/label';
import { Tabs } from '@/shared/ui/tabs';

import styles from './live-feed.module.scss';

export const LiveFeedWidget = ({
  className,
  ...props
}: LiveFeedWidgetProps) => {
  const liveFeedWidgetClasses = cn(styles['live-feed'], className);
  const t = useTranslations('page');

  const [sortType, setSortType] = useState<'all' | 'exclusive'>('all');

  const router = useRouter();

  const is1024Desktop = useMediaQuery('(min-width: 1024px)');
  const is1440Desktop = useMediaQuery('(min-width: 1440px)');
  const is1920Desktop = useMediaQuery('(min-width: 1920px)');

  const extendArray = (
    originalArray: GlobalItem[],
    targetLength: number,
    itemsToAdd: GlobalItem[],
  ) => {
    while (originalArray.length <= targetLength && itemsToAdd.length > 0) {
      originalArray.push([...itemsToAdd].splice(0, 1)[0]);
    }
    return shuffle(originalArray);
  };

  const handleItemsQuantity = ({
    currMedia,
    sortType,
    items,
  }: {
    currMedia: '1920' | '1440' | '1024';
    sortType: 'all' | 'exclusive';
    items: GlobalItem[];
  }) => {
    const mappedQuantity = {
      '1920': 11,
      '1440': 9,
      '1024': 9,
    };

    const filteredItems = items.filter(item =>
      sortType === 'all' ? item : item.type === sortType,
    );
    const quantityByMedia = mappedQuantity[currMedia];
    const concattedItems =
      filteredItems.length < quantityByMedia
        ? extendArray(filteredItems, quantityByMedia, filteredItems)
        : filteredItems;

    return concattedItems;
  };

  //Добавил функционал дополнения массива элементами если длина filteredItems < quantityByMedia тк ломается анимация.

  const itemsToMap = useMemo(
    () =>
      handleItemsQuantity({
        currMedia: is1920Desktop ? '1920' : is1440Desktop ? '1440' : '1024',
        sortType: sortType,
        items: mockedData,
      }),
    [sortType, is1024Desktop, is1440Desktop, is1920Desktop],
  );

  return (
    <div className={liveFeedWidgetClasses} {...props}>
      <div className={styles['top-slot']}>
        <Label
          className={styles['label']}
          labelVariant={'purple'}
          icon={'graph-new-up'}
          variant={'primary'}
        >
          Live Sales
        </Label>

        <Tabs<'all' | 'exclusive'>
          className={styles['tabs-container']}
          defaultActiveTab={'all'}
          onTabsChange={tab => tab && setSortType(tab)}
        >
          <Tabs.ItemFilter value={'all'}>All</Tabs.ItemFilter>
          <Tabs.ItemFilter value={'exclusive'}>Exclusive</Tabs.ItemFilter>
        </Tabs>
      </div>

      <div className={styles['bottom-slot']}>
        <Swiper
          pagination={{
            clickable: true,
          }}
          loop
          observer={true}
          autoplay={{
            pauseOnMouseEnter: true,
            delay: 100,
            reverseDirection: true,
            waitForTransition: true,
          }}
          speed={1300}
          breakpoints={{
            1024: {
              slidesPerView: sortType === 'all' ? 9 : 'auto',
              spaceBetween: 10.5,
              centeredSlides: false,
            },
            1440: {
              slidesPerView: sortType === 'all' ? 9 : 'auto',
              spaceBetween: 14.75,
              centeredSlides: false,
            },
            1920: {
              slidesPerView: sortType === 'all' ? 11 : 'auto',
              spaceBetween: 10,
              centeredSlides: false,
            },
          }}
          modules={[Autoplay]}
          style={
            sortType !== 'all'
              ? {
                marginLeft: 0,
                marginRight: 0,
                display: 'flex',
                width: '100%',
              }
              : {}
          }
          className={'swiper-live-feed'}
        >
          {itemsToMap.map((item, idx) => (
            <SwiperSlide
              onClick={() => router.push(`/shop/${item.category}/${item?.id}`)}
              key={`${idx}-${item.info.title}-${item?.info?.price?.old}`}
            >
              <CardLiveFeedEntity
                info={{
                  title: item?.info?.title,
                  price: {
                    old: item?.info?.price?.old,
                    current: item?.info?.price?.current,
                  },
                }}
                item={item.item}
                rarity={item.rarity}
                tagCategory={item.tagCategory}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};
