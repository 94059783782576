'use client';
import { CardItemEntity } from '@entities/desktop/cards';
import { AddToCartFeature } from '@features/desktop/add-to-cart';
import { AddToFavoriteFeature } from '@features/desktop/add-to-favorite/ui';
import { SelectPetPropertiesFeature } from '@features/select-pet-properties';
import { ContentWidgetItemsBlockProps } from '@widgets/mobile/content/ui/content.types';
import { useRouter } from 'next/navigation';
import React from 'react';

import { CartItemType } from '@/store/cart/cart';

import styles from './content.module.scss';

export const ContentWidget = ({
  headerSlot,
  emptySlot,
  items,
}: ContentWidgetItemsBlockProps) => {
  const router = useRouter();

  if (!items.length && emptySlot) {
    return emptySlot;
  }

  return (
    <div className={styles['items-block']}>
      {headerSlot && <div className={styles['header']}>{headerSlot}</div>}
      <div className={styles['items']}>
        {items?.map((item, idx) => {
          let itemReadyToCart: CartItemType = {
            ...item,
            status: 'available',
            quantity: 1,
          };

          return (
            <CardItemEntity
              onClick={() => router.push(`/shop/${item.category}/${item?.id}`)}
              key={`${item?.id}${idx}`}
              variant={'tertiary'}
              item={item.item}
              rarity={item.rarity}
              info={{
                title: item?.info?.title,
                price: {
                  old: item?.info?.price?.old,
                  current: item?.info?.price?.current as string | number,
                },
              }}
              propertiesSlot={<SelectPetPropertiesFeature item={item} />}
              tagsInfo={item?.tagsInfo}
              topRightSlot={
                <AddToFavoriteFeature variant={'tertiary'} id={item.id} />
              }
              bottomSlot={<AddToCartFeature item={itemReadyToCart} />}
            />
          );
        })}
      </div>
    </div>
  );
};
