import { useRouter } from 'next/navigation';
import React from 'react';

import { Illustration } from '@/shared/ui';
import { Button } from '@/shared/ui/button';

import styles from './price-card-btn.module.scss';

type CheckoutPriceCardFeatureProps = {
  className?: string;
};
export const CheckoutPriceCardFeature = ({
  className,
}: CheckoutPriceCardFeatureProps) => {
  const router = useRouter();
  const handleCheckout = () => router.push('/cart');

  return (
    <Button
      className={styles['action-btn']}
      text="Checkout"
      width="full-w"
      variant="primary"
      iconRight={
        <Illustration
          style={{ color: '#fff' }}
          name={'arrow-right'}
          id={'btn-icon'}
          spriteName={'icons'}
        />
      }
      onClick={handleCheckout}
    />
  );
};
