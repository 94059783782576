'use client';
import { observer } from 'mobx-react-lite';
import { useIsClient } from 'usehooks-ts';

import { AdvertisingEntity } from '@/entities';
import { AdvertisingCloseButton } from '@/shared/components/advertising-close-button';
import { ButtonAdvertising } from '@/shared/ui/desktop/button-advertising';
import { Typography } from '@/shared/ui/typography';
import { useStore } from '@/store/context';

import styles from './adsveritising.module.scss';

type AdvertisingWidgetProps = {};

export const AdvertisingWidget = observer(({}: AdvertisingWidgetProps) => {
  const advStore = useStore()?.advertising;
  const isClient = useIsClient();

  if (!advStore?.showHeaderAdvBlock || !isClient) {
    return null;
  }

  return (
    <AdvertisingEntity
      className={styles['advertising']}
      middleSlot={
        <div className={styles['content']}>
          <Typography className={styles['text']}>
            Get a 5% deposit bonus
          </Typography>
          <ButtonAdvertising label={'Learn More'} />
        </div>
      }
      rightSlot={
        <AdvertisingCloseButton
          onClick={() => advStore?.toggleShowHeaderAdvBlock()}
        />
      }
    />
  );
});
