import cn from 'clsx';
import React from 'react';

import { Illustration } from '@/shared/ui';
import { DropdownItemProps } from '@/shared/ui/desktop/dropdown-item/types';
import { Typography } from '@/shared/ui/typography';

import styles from './dropdown-item.module.scss';

export const DropdownItem: React.FC<DropdownItemProps> = ({
  toggleChipDropDown,
  opened,
  label,
  disabled,
  icon,
  className,
}) => {
  const containerClasses = cn(
    styles.chips,
    {
      [styles['chips-opened']]: opened,
      [styles['chips-disabled']]: disabled,
    },
    className,
  );
  const arrowIconClasses = cn(styles.icon, {
    [styles['icon-opened']]: opened,
  });

  return (
    <div className={containerClasses} onClick={toggleChipDropDown}>
      {icon && icon}
      <Typography id={'drop-down-label'} className={styles['label']}>
        {label}
      </Typography>
      {
        <Illustration
          spanTagClassName={arrowIconClasses}
          name={'alt-arrow-down'}
          spriteName={'icons'}
        />
      }
    </div>
  );
};
