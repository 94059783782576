import { CardCategoryEntity } from '@entities/desktop/cards';
import React from 'react';

import { mockedCategories } from '@/mocks/market-categories';
import { DivElementType } from '@/shared/types/common';

import styles from './categories.module.scss';

type CategoriesWidgetProps = DivElementType & {};

export const CategoriesWidget = ({}: CategoriesWidgetProps) => {
  return (
    <>
      {Array.isArray(mockedCategories) && mockedCategories?.length > 0 && (
        <div className={styles['container']}>
          {mockedCategories?.map((category, idx) => {
            return (
              <CardCategoryEntity
                key={`${idx}-${category?.id}`}
                categoryPath={category?.categoryPath}
                item={category?.item}
                rarity={category?.rarity}
                title={category?.title}
                subtitle={category?.subtitle}
              />
            );
          })}
        </div>
      )}
    </>
  );
};
