import { Overlay } from '@react-aria/overlays';
import cn from 'clsx';
import * as React from 'react';
import { CSSProperties } from 'react';

import styles from './popover.module.scss';

interface PopoverProps {
  children: React.ReactNode;
  className?: string;
  popoverRef?: React.RefObject<HTMLDivElement>;
  style?: CSSProperties;
  isActive?: boolean;
}

export const Popover = (props: PopoverProps) => {
  let ref = React.useRef<HTMLDivElement>(null);
  let { popoverRef = ref, children, className } = props;

  const popperClasses = cn(styles.popper, styles.popper__opened, className);

  return props.isActive ? (
    <Overlay>
      <div ref={popoverRef} className={popperClasses} style={props.style}>
        {children}
      </div>
    </Overlay>
  ) : null;
};
