import cn from 'clsx';
import React from 'react';

import { DropListSearchProps } from '@/shared/components/drop-list-search/types';
import { Filter } from '@/shared/types/common';
import { Illustration } from '@/shared/ui';
import { DropDownWrapper } from '@/shared/ui/desktop/drop-down-wrapper/drop-down-wrapper';
import { DropdownItem } from '@/shared/ui/desktop/dropdown-item';
import { DropListItem } from '@/shared/ui/drop-list-item';

import styles from './drop-list-search.module.scss';

export const SEARCH_BAR_FILTERS: Filter[] = [
  {
    label: 'Pets',
    filter: 'input-pets',
    group: 'search-category',
    icon: (
      <Illustration
        name={'cat'}
        spriteName={'icons'}
        spanTagClassName={styles['item-icon']}
      />
    ),
  },
  {
    label: 'Eggs',
    filter: 'input-eggs',
    group: 'search-category',
    icon: (
      <Illustration
        name={'egg'}
        spriteName={'icons'}
        spanTagClassName={styles['item-icon']}
      />
    ),
  },
  {
    label: 'Potions',
    filter: 'input-potions',
    group: 'search-category',
    icon: <Illustration name={'potion'} spriteName={'icons'} size={22} />,
  },
  {
    label: 'Transport',
    filter: 'input-transport',
    group: 'search-category',
    icon: (
      <Illustration
        spanTagClassName={styles['item-icon']}
        name={'bus'}
        spriteName={'icons'}
      />
    ),
  },
];

export const DropListSearch: React.FC<DropListSearchProps> = ({
  dropListOpened = false,
  selectedChip,
  resetChip,
  toggleChipDropDown,
  selectChip,
}) => {
  const selectedChipClasses = cn(
    styles.selected_chip,
    styles[`${selectedChip?.filter}`],
  );

  const renderChip = (item: Filter, index: number) => {
    return (
      <DropListItem
        className={styles['drop_list--item']}
        key={index}
        onPress={() => selectChip(item)}
        title={item.label}
        iconLeft={item.icon}
      />
    );
  };

  return (
    <div className={styles['container']}>
      {selectedChip ? (
        <div className={selectedChipClasses}>
          {selectedChip.label}
          <Illustration
            name={'close-circle-header'}
            spriteName={'icons'}
            size={22}
            spanTagClassName={styles.icon}
            onClick={resetChip}
          />
        </div>
      ) : (
        <DropdownItem
          className={styles['drop-down-item']}
          label="All"
          opened={dropListOpened}
          toggleChipDropDown={toggleChipDropDown}
        />
      )}

      <div
        className={cn(styles['wrapper'], {
          [styles['wrapper-opened']]: dropListOpened,
        })}
      >
        <DropDownWrapper
          close={toggleChipDropDown}
          isOpened={dropListOpened}
          className={styles.drop_list}
        >
          <>{SEARCH_BAR_FILTERS.map(renderChip)}</>
        </DropDownWrapper>
      </div>
    </div>
  );
};
