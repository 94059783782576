import { observer } from 'mobx-react-lite';
import { useIsClient } from 'usehooks-ts';

import { ButtonFavorite } from '@/shared/ui/desktop/button-favorite';
import { useStore } from '@/store/context';
export type AddToFavoriteFeatureProps = {
  variant: 'primary' | 'secondary' | 'tertiary';
  id: string | number;
};
export const AddToFavoriteFeature = observer(
  ({ variant, id }: AddToFavoriteFeatureProps) => {
    const favorites = useStore().favorite;
    const isInFavorite = favorites.favoriteIds.includes(id);
    const isClient = useIsClient();

    if (!isClient) {
      return null;
    }

    return (
      <ButtonFavorite
        variant={
          variant === 'tertiary' || variant === 'secondary'
            ? 'secondary'
            : 'primary'
        }
        onPress={() => favorites.toggleFavorite(id)}
        isLiked={isInFavorite}
      />
    );
  },
);
