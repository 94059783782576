import { SidebarSaleProps } from '@entities/desktop/sidebars/sidebar-sale/ui/sidebar-sale.types';
import cn from 'clsx';
import Image from 'next/image';

import { Divider, Illustration } from '@/shared/ui';
import { Typography } from '@/shared/ui/typography';

import styles from './sidebar-sale.module.scss';

export const SidebarSaleEntity = ({
  className,
  title,
  imgSrc,
  subtitle,
  bottomSlot,
  ...props
}: SidebarSaleProps) => {
  const sidebarSaleClasses = cn(styles['sidebar-sale'], className);

  return (
    <div className={sidebarSaleClasses} {...props}>
      <Divider className={styles['divider']} direction={'vertical'} />{' '}
      {/*вертикальный дивайдер появится когда будет у одного из родителей четко задана высота*/}
      <div className={styles['sidebar-content']}>
        {imgSrc ? (
          <Image
            className={styles['image']}
            src={imgSrc}
            alt={'sidebar-sale'}
            width={224}
            height={142}
          />
        ) : (
          <Illustration
            name={'sidebar-sale-default-picture'}
            width={223}
            height={142}
          />
        )}
        <div className={styles['text-block']}>
          {title && (
            <Typography
              className={styles['sidebar-title']}
              size={'m'}
              weight={'semi-bold'}
            >
              {title}
            </Typography>
          )}
          {subtitle && (
            <Typography
              className={styles['sidebar-subtitle']}
              size={'s'}
              weight={'medium'}
            >
              {subtitle}
            </Typography>
          )}
        </div>
        <div className={styles['items-list']}>{bottomSlot}</div>
      </div>
    </div>
  );
};
