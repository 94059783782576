import cn from 'clsx';
import React from 'react';

import { Typography } from '@/shared/ui/typography';

import styles from './button-advertising.module.scss';
import { ButtonAdvertisingProps } from './types';

export const ButtonAdvertising: React.FC<ButtonAdvertisingProps> = ({
  disabled,
  label,
  ...props
}) => {
  const containerClasses = cn(styles.container, {
    [styles['container-disabled']]: disabled,
  });

  return (
    <button role="button" className={containerClasses} {...props}>
      <Typography as={'span'} size="base" weight="bold">
        {label}
      </Typography>
    </button>
  );
};
