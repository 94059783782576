import { SidebarCategoryProps } from '@entities/desktop/sidebars/sidebar-category/ui/sidebar-category.types';
import cn from 'clsx';

import { Divider, Typography } from '@/shared/ui';

import styles from './sidebar-category.module.scss';

export const SidebarCategoryEntity = ({
  className,
  title = 'Category',
  topSlot = null,
  bottomSlot = null,
  ...props
}: SidebarCategoryProps) => {
  const sidebarCategoryClasses = cn(styles['sidebar-category'], className);

  return (
    <div className={sidebarCategoryClasses} {...props}>
      <Typography className={styles['sidebar-title']} weight={'semi-bold'}>
        {title}
      </Typography>
      <div className={styles['content']}>
        <div className={styles['top-slot']}>{topSlot}</div>

        {topSlot && bottomSlot && <Divider direction={'horizontal'} />}
        <div className={styles['bottom-slot']}>{bottomSlot}</div>
      </div>
    </div>
  );
};
