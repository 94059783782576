import { SidebarFilterProps } from '@entities/desktop/sidebars/sidebar-filter/ui/sidebar-filter.types';
import cn from 'clsx';

import { Divider, Typography } from '@/shared/ui';

import styles from './sidebar-filter.module.scss';
export const SidebarFilterEntity = ({
  className,
  title = 'Age',
  children,
  ...props
}: SidebarFilterProps) => {
  const sidebarFilterClasses = cn(styles['sidebar-filter'], className);

  return (
    <div className={sidebarFilterClasses} {...props}>
      <Divider className={styles['divider']} direction={'vertical'} />
      {/*вертикальный дивайдер появится когда будет у одного из родителей четко задана высота*/}
      <div className={styles['sidebar-content']}>
        <Typography className={styles['sidebar-title']}>{title}</Typography>
        <div className={styles['items-list']}>{children}</div>
      </div>
    </div>
  );
};
