import cn from 'clsx';
import Link from 'next/link';

import { HyperLinkProps } from '@/shared/ui/desktop/link/link.types';
import { Typography } from '@/shared/ui/typography';

import styles from './link.module.scss';
export const HyperLink = ({
  children,
  icon,
  onPress,
  className,
}: HyperLinkProps) => {
  return (
    <Typography className={cn(styles.link, className)} onClick={onPress}>
      {icon}
      {children}
    </Typography>
  );
};
