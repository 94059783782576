import { BaseNotification } from '@/store/notifications/notifications';

const getRandomElement = (arr: any[]) => {
  const randomIndex = Math.floor(Math.random() * arr.length);

  return arr[randomIndex];
};

export const add = (arr: BaseNotification[]): BaseNotification => {
  const variants = [
    'success',
    'error',
    'warn',
    'balance',
    'discount',
    'password',
  ];
  const textByVariant = {
    success: {
      title: 'Successfully!',
      subtitle:
        'Items have been successfully purchased and moved to your inventory!',
    },
    error: {
      title: 'Error!',
      subtitle:
        'There are not enough funds on your balance to make a purchase!',
    },
    warn: {
      title: 'Warning!',
      subtitle:
        'Item withdrawal is temporarily unavailable due to technical work!',
    },
    balance: {
      title: '$ 4.14 returned to your balance-info!',
      subtitle:
        'Your purchase was canceled for technical reasons, we returned your money',
    },
    discount: {
      title: 'Discounts up to 20%',
      subtitle: 'Go to the eggs category and buy new items quickly!',
    },
    password: {
      title: 'Set a password!',
      subtitle: 'This is necessary so as not to lose access to your account',
    },
  };
  const toast = getRandomElement(variants);
  const randomNotification: BaseNotification = {
    id: `${toast}-${arr.length + 1}-${new Date().getMilliseconds()}`,
    variant: toast,
    title: textByVariant[toast as keyof typeof textByVariant]?.title,
    subtitle: textByVariant[toast as keyof typeof textByVariant]?.subtitle,
    delay: 3,
  };

  return randomNotification;
};
