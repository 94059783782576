'use client';
import { CardItemOfferEntity } from '@entities/desktop/cards';
import { AddToCartOfferFeature } from '@features/desktop/pet/offer/add-to-cart/ui';
import { Offer, OfferWidgetProps } from '@widgets/desktop/offer/types';
import React from 'react';

import { Divider, Illustration } from '@/shared/ui';
import { Typography } from '@/shared/ui/typography';

import styles from './offer.module.scss';

const items: Offer[] = [
  {
    tagsRarity: ['common', 'uncommon'],
    tagsCategory: ['ride'],
    rarity: 'uncommon',
    item: 'buffalo',
    priceInfo: { old: 12, current: 6 },
  },
  {
    tagsRarity: ['common', 'uncommon', 'legendary'],
    tagsCategory: ['ride'],
    rarity: 'legendary',
    item: 'buffalo',
    priceInfo: { old: 12, current: 6 },
  },
  {
    tagsRarity: ['common', 'ultra-rare'],
    tagsCategory: ['ride'],
    rarity: 'uncommon',
    item: 'buffalo',
    priceInfo: { old: 12, current: 6 },
  },
  {
    tagsRarity: ['common', 'uncommon', 'rare'],
    tagsCategory: ['fly'],
    rarity: 'ultra-rare',
    item: 'buffalo',
    priceInfo: { old: 12, current: 6 },
  },
  {
    tagsRarity: ['common', 'uncommon', 'rare'],
    tagsCategory: ['fly'],
    rarity: 'ultra-rare',
    item: 'buffalo',
    priceInfo: { old: 12, current: 6 },
  },
  {
    tagsRarity: ['common', 'uncommon', 'rare'],
    tagsCategory: ['fly'],
    rarity: 'ultra-rare',
    item: 'buffalo',
    priceInfo: { old: 12, current: 6 },
  },
];

export const OfferWidget: React.FC<OfferWidgetProps> = ({}) => {
  const renderOfferItem = (item: Offer, index: number) => (
    <React.Fragment key={`offer-${item?.item}-${index}-${item?.rarity}`}>
      <CardItemOfferEntity {...item} bottomSlot={<AddToCartOfferFeature />} />
      {index !== items.length - 1 && (
        <Divider style={{ minHeight: 1 }} direction={'horizontal'} />
      )}
    </React.Fragment>
  );

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles['header-title-container']}>
          <Illustration
            name={'notes'}
            size={26}
            style={{ color: 'rgba(105, 65, 198, 1)' }}
            spriteName={'icons'}
          />
          <Typography className={styles['header-title-text']}>
            Same items
          </Typography>
        </div>
        <Typography
          className={styles['header-title-text']}
        >{`${items.length} pcs`}</Typography>
      </div>
      <div className={styles['scroll-container']}>
        {items.map(renderOfferItem)}
      </div>
    </div>
  );
};
