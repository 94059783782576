'use client';
import { motion } from 'framer-motion';

import { Illustration } from '@/shared/ui';
import { Button } from '@/shared/ui/button';

import styles from './add-to-cart.module.scss';
export const AddToCartOfferFeature = () => {
  const handleAddToCart = () => {};

  return (
    <Button
      as={motion.button}
      whileTap={{ scale: 0.95 }}
      iconLeft={
        <Illustration
          style={{ color: '#A58DDD' }}
          name={'cart-plus'}
          id={'btn-icon'}
          spriteName={'icons'}
        />
      }
      variant={'secondary'}
      className={styles['action-btn']}
      onClick={handleAddToCart}
      text={'Add to cart'}
    />
  );
};
