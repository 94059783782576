'use client';
import { useTimer } from '@hooks/client';
import { validationSchema } from '@widgets/desktop/settings/ui/validation';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';

import { CHANGE_PASSWORD } from '@/shared/api/endpoints';
import usersApi from '@/shared/api/usersApi';
import { Button, Divider, Illustration, Typography } from '@/shared/ui';
import { InputAuth } from '@/shared/ui/desktop/input-auth';
import { AuthInfo } from '@/store/authorization/types';
import { useStore } from '@/store/context';

import styles from './settings.module.scss';

export const SettingsWidget = () => {
  return (
    <div className={styles.container}>
      <SettingsWidget.Header />
      <SettingsWidget.EmailBlock />
      <SettingsWidget.PasswordBlock />
    </div>
  );
};

// eslint-disable-next-line react/display-name
SettingsWidget.Header = () => {
  return (
    <div className={styles.header}>
      <Typography className={styles['header__title']}>Settings</Typography>
      <Typography className={styles['header__description']}>
        Edit your profile!
      </Typography>
      <Divider direction={'horizontal'} />
    </div>
  );
};

// eslint-disable-next-line react/display-name
SettingsWidget.EmailBlock = observer(() => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const notificationStore = useStore().notifications;

  const {
    timer,
    count,
    resetCountdown,
    startCountdown,
    stopCountdown,

    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useTimer({
    duration: 60,
  });

  const handleLinkSent = () => {
    notificationStore?.addNotification({
      variant: 'success',
      title: 'The email has been sent',
      subtitle: 'Follow the link inside the email to confirm your email',
      delay: 5,
      id: 'email-successfully-sent',
    });
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    stopCountdown();
  }, []);

  return (
    <div className={styles.content}>
      <InputAuth
        iconName={'inbox'}
        value={'test@gmail.com'}
        placeholder="Email"
        withErrorMessage={false}
        className={styles['input']}
        iconLeft={
          <Illustration
            name={'danger-triangle'}
            style={{ color: '#EB3C3C' }}
            spriteName={'icons'}
          />
        }
      />
      <Button
        onClick={() => {
          resetCountdown();
          startCountdown();
          handleLinkSent();
        }}
        variant={'quaternary'}
        className={styles.btn}
        disabled={count !== 60 && count !== 0}
        text={
          count !== 60 && count !== 0
            ? `Resend the letter in ${timer.substring(3)}`
            : 'Confirm email'
        }
      />
    </div>
  );
});

// eslint-disable-next-line react/display-name
SettingsWidget.PasswordBlock = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [changePasswordOpened, setChangePasswordOpened] = useState(false);

  if (changePasswordOpened) {
    return <SettingsWidget.PasswordOpened />;
  }

  return (
    <div className={styles.content}>
      <InputAuth
        iconName={'lock-password'}
        value={'dsadasdasdas'}
        placeholder="Password"
        withErrorMessage={false}
        securityInput
        showEye={false}
        filledDisabled
        className={styles['input']}
      />
      <Button
        onClick={() => setChangePasswordOpened(true)}
        variant={'quaternary'}
        className={styles.btn}
        text={'Change password'}
      />
    </div>
  );
};

// eslint-disable-next-line react/display-name
SettingsWidget.PasswordOpened = observer(() => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const store = useStore();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: validationSchema,
    onSubmit: values => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  const handlePasswordChanged = () => {
    store.notifications?.addNotification({
      variant: 'success',
      title: 'Successfully!',
      subtitle: 'Password successfully changed!',
      delay: 5,
      id: 'email-successfully-sent',
    });
  };

  const onSubmit = async () => {
    try {
      await usersApi.patch<AuthInfo>(CHANGE_PASSWORD, {
        old_password: formik.values.oldPassword,
        new_password: formik.values.newPassword,
      });
      handlePasswordChanged();
    } catch (err: any) {
      store.authorization.setAuthErrorMessage(err.response.data.message);
    }
  };

  return (
    <div className={styles.content}>
      <InputAuth
        iconName={'lock-password'}
        value={formik.values.oldPassword}
        onChange={formik.handleChange('oldPassword')}
        onBlur={formik.handleBlur('oldPassword')}
        onInput={() => formik.setFieldTouched('oldPassword')}
        placeholder="Old password"
        withErrorMessage={false}
        securityInput
        errorMessage={
          store.authorization.authError ||
          (formik.touched.oldPassword ? formik.errors.oldPassword : '')
        }
      />
      <InputAuth
        iconName={'lock-password'}
        value={formik.values.newPassword}
        onChange={formik.handleChange('newPassword')}
        onBlur={formik.handleBlur('newPassword')}
        onInput={() => formik.setFieldTouched('newPassword')}
        placeholder="New password"
        withErrorMessage={false}
        securityInput
        errorMessage={
          formik.touched.newPassword ? formik.errors.newPassword : ''
        }
      />
      <InputAuth
        iconName={'lock-password'}
        value={formik.values.confirmPassword}
        onChange={formik.handleChange('confirmPassword')}
        onBlur={formik.handleBlur('confirmPassword')}
        onInput={() => formik.setFieldTouched('confirmPassword')}
        placeholder="Confirm password"
        withErrorMessage={false}
        securityInput
        errorMessage={
          formik.touched.confirmPassword ? formik.errors.confirmPassword : ''
        }
      />
      <Button
        onClick={onSubmit}
        variant={'quaternary'}
        className={styles.btn}
        text={'Change password'}
        disabled={!formik.isValid}
      />
    </div>
  );
});
