import { CardItemOfferEntity } from '@entities/desktop/cards';
import { HistoryItem } from '@widgets/desktop/inventory-history/ui/history.types';
import React from 'react';

import { Divider, Illustration, Typography } from '@/shared/ui';
import { AdditionalInfoMobile } from '@/shared/ui/mobile/additional-info';

import styles from './history.module.scss';

export const historyItems: HistoryItem[] = [
  {
    tagsRarity: ['common', 'uncommon'],
    tagsCategory: ['ride'],
    rarity: 'uncommon',
    title: 'Cow',
    item: 'buffalo',
    status: 'canceled',
    priceInfo: { old: 12, current: 6 },
    age: 'teen',
  },
  {
    tagsRarity: ['common', 'uncommon', 'legendary'],
    tagsCategory: ['ride'],
    rarity: 'legendary',
    item: 'buffalo',
    title: 'Cow',
    status: 'canceled',
    priceInfo: { old: 12, current: 6 },
    age: 'teen',
  },
  {
    tagsRarity: ['common', 'ultra-rare'],
    tagsCategory: ['ride'],
    rarity: 'uncommon',
    item: 'buffalo',
    title: 'Cow',
    status: 'completed',
    priceInfo: { old: 12, current: 6 },
    age: 'teen',
  },
  {
    tagsRarity: ['common', 'uncommon', 'rare'],
    tagsCategory: ['fly'],
    rarity: 'ultra-rare',
    item: 'buffalo',
    title: 'Cow',
    status: 'completed',
    priceInfo: { old: 12, current: 6 },
    age: 'teen',
  },
];

export const InventoryHistoryWidget: React.FC = () => {
  const renderOfferItem = (item: HistoryItem, index: number) => (
    <React.Fragment key={`offer-${item?.item}-${index}-${item?.rarity}`}>
      <CardItemOfferEntity
        {...item}
        bottomSlot={
          <AdditionalInfoMobile.Timer status={item.status} duration={0} />
        } //@TODO мобильный адишнл в десктопе :(
      />
      {index !== historyItems.length - 1 && (
        <Divider style={{ minHeight: 1 }} direction={'horizontal'} />
      )}
    </React.Fragment>
  );

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles['header-title-container']}>
          <Illustration
            name={'clock-green'}
            size={26}
            style={{ color: 'rgba(105, 65, 198, 1)' }}
            spriteName={'icons'}
          />
          <Typography className={styles['header-title-text']}>
            History
          </Typography>
        </div>
        <Typography
          className={styles['header-title-text']}
        >{`${historyItems.length} items`}</Typography>
      </div>
      <div className={styles['scroll-container']}>
        {historyItems.map(renderOfferItem)}
      </div>
    </div>
  );
};
