import cn from 'clsx';
import React from 'react';

import { Illustration } from '@/shared/ui';
import { SideBarItemProps } from '@/shared/ui/desktop/sidebar-item/types';
import { Typography } from '@/shared/ui/typography';

import styles from './sidebar-item.module.scss';

export const SidebarItem = ({
  onClick,
  icon,
  text,
  active,
  disabled,
  className,
  width,
  showArrow,
  ...props
}: SideBarItemProps) => {
  const containerClasses = cn(styles.container, styles[`width--${width}`], {
    [styles.disabled]: disabled,
    [styles.active]: active,
    className,
  });

  return (
    <button {...props} className={containerClasses} onClick={onClick}>
      <div className={styles.content_container}>
        {icon}
        <Typography className={styles['text']}>{text}</Typography>
      </div>
      {showArrow && (
        <Illustration
          spanTagClassName={styles['arrow-icon']}
          style={{ color: '#6941C6' }}
          name={'arrow-right'}
          spriteName={'icons'}
          size={22}
        />
      )}
    </button>
  );
};
