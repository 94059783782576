'use client';
import { ChangeCurrencyFeature } from '@features/desktop/change-currency';
import { useQueryString } from '@hooks/client';
import { useProtectedRouter } from '@hooks/client/use-protected-router';
import { SidebarWidget } from '@widgets/desktop';
import {
  HeaderSidebarWrapper,
  HeaderWidgetProps,
} from '@widgets/desktop/header/ui/header.types';
import cn from 'clsx';
import { AnimatePresence } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { usePathname, useRouter } from 'next/navigation';
import React from 'react';
import { useIsClient } from 'usehooks-ts';

import { DropListBalance } from '@/shared/components/drop-list-balance';
import { DropListProfile } from '@/shared/components/drop-list-profile';
import { InputSearchAutocomplete } from '@/shared/components/input-search-autocomplete';
import { TabItemNavigation } from '@/shared/types/common';
import { Divider, Illustration, Logo } from '@/shared/ui';
import { Button } from '@/shared/ui';
import { Tabs } from '@/shared/ui/tabs';
import { TagCategoryVariants } from '@/shared/ui/tag-category/tag-category.types';
import { useStore } from '@/store/context';

import styles from './header.module.scss';

const tabs: TabItemNavigation[] = [
  {
    id: 0,
    href: 'inventory',
    text: 'Inventory',
    icon: (
      <Illustration
        name={'bag'}
        spriteName={'icons'}
        size={30}
        style={{ color: '#40BB18' }}
      />
    ),
  },
  {
    id: 1,
    href: 'favorite',
    text: 'Wishlist',
    icon: (
      <Illustration
        name={'heart'}
        spriteName={'icons'}
        size={30}
        style={{ color: '#EB3C3C' }}
      />
    ),
  },
  {
    id: 2,
    href: 'cart',
    text: 'Cart',
    icon: (
      <Illustration
        name={'cart'}
        spriteName={'icons'}
        size={30}
        style={{ color: '#6941C6' }}
      />
    ),
  },
];

const tabs0: { name: string; icon: TagCategoryVariants }[] = [
  { name: 'Fly', icon: 'fly' },
  { name: 'Ride', icon: 'ride' },
];

const tabs1: { name: string; icon: TagCategoryVariants }[] = [
  { name: 'Neon', icon: 'neon' },
  { name: 'Mega-Neon', icon: 'mega-neon' },
];

const tabs2: { name: string }[] = [
  { name: 'Pets' },
  { name: 'Eggs' },
  { name: 'Potions' },
  { name: 'Transport' },
];

export const HeaderWidget = ({}: HeaderWidgetProps) => {
  const router = useRouter();
  const pathname = usePathname();

  const { createQueryString } = useQueryString();

  return (
    <HeaderWidget.SidebarWrapper>
      <header className={styles.header}>
        <div className={styles.content}>
          <div className={styles['content__top-slot']}>
            <div className={styles['content__top-slot-left']}>
              <Logo className={styles['logo']} />
            </div>

            <div className={styles['content__top-slot-middle']}>
              <HeaderWidget.CategoryButton />

              <HeaderWidget.SearchBarWithDropdown />
              {/* <InputSearch
                onSubmit={() => {}}
              />*/}
            </div>

            <div className={styles['content__top-slot-right']}>
              <HeaderWidget.TabsNav />
              <DropListProfile />
            </div>
          </div>

          <div className={styles['content__bottom-slot']}>
            <div className={styles['content__bottom-slot-left']}>
              <Tabs
                onTabsChange={() => {}}
                defaultActiveTab={'Hot Sale'}
                className={styles.tabs_bottom}
              >
                <Tabs.Item
                  value={'Hot Sale'}
                  onClick={() =>
                    router.push(
                      createQueryString({
                        pathname: '/shop',
                        name: 'sale',
                        value: 'hot-sale',
                        asMultipleParams: true,
                      }),
                    )
                  }
                  variant="secondary"
                  iconsLeft={
                    <Illustration
                      name={'fire-square'}
                      spriteName={'icons'}
                      size={20}
                      style={{ color: '#EB3C3C' }}
                    />
                  }
                >
                  Hot Sale
                </Tabs.Item>
                <Tabs.Item
                  value={'New'}
                  variant="tertiary"
                  onClick={() =>
                    router.push(
                      createQueryString({
                        pathname: '/shop',
                        name: 'category',
                        value: 'new',
                        asMultipleParams: true,
                      }),
                    )
                  }
                  iconsLeft={
                    <Illustration
                      name={'lightning'}
                      spriteName={'icons'}
                      size={20}
                      style={{ color: '#40BB18' }}
                    />
                  }
                >
                  New
                </Tabs.Item>
              </Tabs>
              <Divider className={styles['divider']} direction="vertical" />
              <Tabs
                onTabsChange={() => {}}
                defaultActiveTab={'Egg'}
                className={styles.tabs_bottom}
              >
                {tabs2.map((tab, idx) => (
                  <Tabs.Item
                    onClick={() =>
                      router.push(
                        createQueryString({
                          pathname: '/shop',
                          name: 'category',
                          value: tab.name.toLowerCase(),
                          asMultipleParams: false,
                        }),
                      )
                    }
                    key={`header-${idx}-${tab.name}-2`}
                    value={tab.name}
                    variant="primary"
                  >
                    {tab.name}
                  </Tabs.Item>
                ))}
              </Tabs>
              <Divider className={styles['divider']} direction="vertical" />
              <Tabs
                onTabsChange={() => {}}
                defaultActiveTab={'Neon'}
                className={styles.tabs_bottom}
              >
                {tabs1.map((tab, idx) => (
                  <Tabs.Item
                    key={`header-${idx}-${tab.name}-1`}
                    value={tab.name}
                    onClick={() =>
                      router.push(
                        createQueryString({
                          pathname: '/shop',
                          name: 'property',
                          value: tab.name.toLowerCase(),
                          enableToggle: false,
                        }),
                      )
                    }
                    tagCategory={tab.icon}
                    variant="primary"
                  >
                    {tab.name}
                  </Tabs.Item>
                ))}
              </Tabs>

              <Tabs
                onTabsChange={() => {}}
                defaultActiveTab={'Neon'}
                className={styles.tabs_bottom}
              >
                {tabs0.map((tab, idx) => (
                  <Tabs.Item
                    key={`header-${idx}-${tab.name}-1`}
                    value={tab.name}
                    onClick={() =>
                      router.push(
                        createQueryString({
                          pathname: '/shop',
                          name: 'property',
                          value: tab.name.toLowerCase(),
                          enableToggle: false,
                        }),
                      )
                    }
                    tagCategory={tab.icon}
                    variant="primary"
                  >
                    {tab.name}
                  </Tabs.Item>
                ))}
              </Tabs>
            </div>

            <div className={styles['content__bottom-slot-right']}>
              {/*<DropListGame />*/}
              <DropListBalance />
              <ChangeCurrencyFeature />
            </div>
          </div>
        </div>
      </header>

      <HeaderWidget.Sidebar />
    </HeaderWidget.SidebarWrapper>
  );
};

HeaderWidget.TabsNav = observer(() => {
  const router = useProtectedRouter();
  const pathname = usePathname();

  const cart = useStore()?.cart;
  const totalAmount = cart?.totalSumm >= 0 ? cart?.totalSumm : 0;
  const isClient = useIsClient();

  return (
    <Tabs<string>
      className={styles.tabs}
      onTabsChange={item => item && router.push(`/${item}`)}
    >
      {tabs.map(tab => (
        <Tabs.ItemNavigation
          isActive={String(pathname).includes(tab?.href)}
          key={`${tab.text}${tab.id}`}
          icon={tab.icon}
          badgeCount={cart?.totalQuantity}
          withBadge={isClient && tab.href === 'cart' && totalAmount > 0}
          value={tab.href}
        >
          {tab.text}
        </Tabs.ItemNavigation>
      ))}
    </Tabs>
  );
});

HeaderWidget.SearchBarWithDropdown = observer(() => {
  return <InputSearchAutocomplete />;
});

HeaderWidget.CategoryButton = observer(() => {
  const sidebarStore = useStore().sidebar;

  return (
    <Button
      onClick={() => sidebarStore.toggleSidebar()}
      iconRight={<Illustration name="widget" size={22} spriteName="icons" />}
      text={'Categories'}
      variant="secondary"
      className={styles['category-button']}
    />
  );
});

HeaderWidget.Sidebar = observer(() => {
  const sidebarStore = useStore().sidebar;
  const isSidebarOpened = sidebarStore.isSidebarOpened;

  return (
    <AnimatePresence initial={false}>
      {isSidebarOpened && (
        <div className={styles['sidebar-widget']}>
          <div className={styles['slot']}>
            <SidebarWidget />
          </div>
        </div>
      )}
    </AnimatePresence>
  );
});
HeaderWidget.SidebarWrapper = observer(({ children }: HeaderSidebarWrapper) => {
  const sidebarStore = useStore().sidebar;
  const isSidebarOpened = sidebarStore.isSidebarOpened;

  return (
    <>
      <div
        className={cn(
          styles['header-wrapper'],
          styles[
            `header-pos__${
              isSidebarOpened ? 'sidebar-opened' : 'sidebar-closed'
            }`
          ],
        )}
      >
        {children}
      </div>
    </>
  );
});
