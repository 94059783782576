'use client';
import {
  InviteAsFriendFeature,
  SellerVerificationFeature,
  WaitingSellerAccountFeature,
  WithdrawalSuccessFeature,
} from '@features/items-withdrawal';
import { PickUpItemsFeature } from '@features/items-withdrawal/05-pickup-items/ui';
import { WithdrawalErrorFeature } from '@features/items-withdrawal/error/ui/error';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { ModalWrapper } from '@/shared/ui';
import { useStore } from '@/store/context';
import { itemsWithdrawalSteps } from '@/store/items-withdrawal/items-withdrawal';

import { SelectAccountFeature } from '../../../../features/items-withdrawal/01-select-account';
import styles from './items-withdrawal.module.scss';

export const ItemsWithdrawalWidget = observer(() => {
  const withdrawalStore = useStore()?.withdrawal;
  const currentStep = withdrawalStore?.openedForm;

  return (
    <ModalWrapper
      close={() => withdrawalStore?.close()}
      isOpened={!!withdrawalStore.openedForm}
    >
      <>
        {currentStep === itemsWithdrawalSteps.SELECT_ACCOUNT && (
          <SelectAccountFeature />
        )}
        {currentStep === itemsWithdrawalSteps.SELLER_VERIFICATION && (
          <SellerVerificationFeature />
        )}
        {currentStep === itemsWithdrawalSteps.INVITE_SELLER_AS_FRIEND && (
          <InviteAsFriendFeature />
        )}
        {currentStep === itemsWithdrawalSteps.WAITING_SELLER_ACCOUNT && (
          <WaitingSellerAccountFeature />
        )}
        {currentStep === itemsWithdrawalSteps.PICK_UP_ITEMS && (
          <PickUpItemsFeature />
        )}
        {currentStep === itemsWithdrawalSteps.ERROR && (
          <WithdrawalErrorFeature />
        )}
        {currentStep === itemsWithdrawalSteps.SUCCESS && (
          <WithdrawalSuccessFeature />
        )}
      </>
    </ModalWrapper>
  );
});
