'use client';
import { observer } from 'mobx-react-lite';
import React, { MouseEvent } from 'react';

import { Illustration } from '@/shared/ui';
import { Button } from '@/shared/ui/button';
import { CartItemType } from '@/store/cart/cart';
import { useStore } from '@/store/context';

import styles from './price-card-btn.module.scss';
type AddToCartPriceCardFeatureProps = {
  className?: string;
  item: CartItemType | null;
};

export const AddToCartPriceCardFeature = observer(
  ({ item, className }: AddToCartPriceCardFeatureProps) => {
    const cart = useStore()?.cart;
    const handleActionButtonClick = (
      e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    ) => {
      if (item) {
        e?.stopPropagation();
        cart?.addToCart(item);
      }
    };

    return (
      <Button
        className={styles['action-btn']}
        text="Add to cart"
        variant="primary"
        onClick={handleActionButtonClick}
        iconLeft={
          <Illustration
            name={'cart-plus'}
            spriteName={'icons'}
            id={'btn-icon'}
            style={{ color: '#FDFDFD' }}
          />
        }
      />
    );
  },
);
