'use client';
import { ToastEntity } from '@entities/toast';

import { Illustration, ProgressIndicator } from '@/shared/ui';
import { Button } from '@/shared/ui/button';

import { ToastWidgetProps } from './toast.types';

export const ToastWidget = ({
  title,
  subtitle,
  variant,
  delay,
}: ToastWidgetProps) => {
  const handleClose = () => {
    alert('closed');
  };

  return (
    <ToastEntity
      title={title}
      subtitle={subtitle}
      handleClose={() => handleClose()}
      variant={variant}
      bottomSlot={
        variant === 'discount' ? (
          <Button
            width={'full-w'}
            variant={'secondary'}
            onClick={() => {}}
            text={'Go to category'}
          />
        ) : variant === 'password' ? (
          <Button
            width={'full-w'}
            variant={'primary'}
            onClick={() => {}}
            iconLeft={
              <Illustration size={18} name={'settings'} spriteName={'icons'} />
            }
            text={'Go to settings'}
          />
        ) : (
          <ProgressIndicator delay={delay} variant={variant} />
        )
      }
    />
  );
};
