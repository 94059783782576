'use client';
import { CardPriceItemPageEntity } from '@entities/desktop/cards';
import dynamic from 'next/dynamic';

const LineChartComponent = dynamic(
  () =>
    import('../../../entities/desktop/diagram').then(comp => ({
      default: comp.LineChart,
    })),
  {
    ssr: false,
  },
);

import { AdjustItemQuantityFeature } from '@features/adjust-item-quantity';
import { AddToCartPriceCardFeature } from '@features/desktop/pet/price-card/add-to-cart/ui';
import { CheckoutPriceCardFeature } from '@features/desktop/pet/price-card/checkout/ui';
import { FastBuyPriceCardFeature } from '@features/desktop/pet/price-card/fast-buy/ui';
import {
  PriceCardProps,
  PriceCardWidgetCheckoutProps,
  PriceCardWidgetPriceInfoProps,
} from '@widgets/desktop/price-card/types';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIsClient } from 'usehooks-ts';

import { Divider } from '@/shared/ui';
import { Plate } from '@/shared/ui/desktop/plate';
import { CartItemType } from '@/store/cart/cart';
import { useStore } from '@/store/context';

import styles from './price-card.module.scss';

export const PriceCardWidget = ({ itemId }: PriceCardProps) => {
  const isClient = useIsClient();

  return (
    <article className={styles.container}>
      <div className={styles['top']}>
        <PriceCardWidget.PriceInfo itemId={itemId} />
        <Divider direction="horizontal" />
        <div className={styles.buttons_container}>
          <PriceCardWidget.Checkout itemId={itemId} />
          <FastBuyPriceCardFeature />
        </div>
      </div>
      {!isClient ? (
        <Plate className={styles['plate']} />
      ) : (
        <LineChartComponent
          labels={[
            'Feb 05, 2023',
            'Feb 06, 2023',
            'Feb 07, 2023',
            'Feb 08, 2023',
            'Feb 09, 2023',
            'Feb 10, 2023',
            'Feb 11, 2023',
            'Feb 11, 2023',
          ]}
          data={[0.1, 0.2, 0.3, 0.9, 0.5, 0.3, 0.3, 0.2]}
        />
      )}
    </article>
  );
};

PriceCardWidget.Checkout = observer(
  ({ itemId }: PriceCardWidgetCheckoutProps) => {
    const shop = useStore()?.shop;

    const cart = useStore()?.cart;
    const item = itemId && shop?.getMappedCards.get(itemId);

    const itemPropertiesId =
      itemId && shop?.getMappedCards.get(itemId)?.propertiesSetId;

    const itemAge = itemId && shop?.getMappedCards.get(itemId)?.info.age;
    const isItemInCart =
      itemPropertiesId &&
      itemAge &&
      cart?.isItemInCart(itemPropertiesId, itemAge);

    let itemReadyToCart: CartItemType | null = item
      ? {
        ...item,
        status: 'available',
        quantity: 1,
      }
      : null;

    const isClient = useIsClient();

    if (!isClient || !itemReadyToCart) {
      return <Plate />;
    }

    return !isItemInCart ? (
      <AddToCartPriceCardFeature item={itemReadyToCart} />
    ) : (
      <div className={styles.top_button_container}>
        <AdjustItemQuantityFeature
          item={itemReadyToCart}
          minQuantity={0}
          className={styles['quantity-container']}
        />
        <Divider direction="vertical" />
        <div className={styles.stepper_container}>
          <CheckoutPriceCardFeature />
        </div>
      </div>
    );
  },
);

PriceCardWidget.PriceInfo = observer(
  ({ itemId }: PriceCardWidgetPriceInfoProps) => {
    const shop = useStore()?.shop;

    const itemPriceInfo =
      itemId && shop?.getMappedCards.get(itemId)?.info?.price;

    return itemPriceInfo ? (
      <CardPriceItemPageEntity
        text={'Best price'}
        priceInfo={{ old: itemPriceInfo?.old, current: itemPriceInfo?.current }}
      />
    ) : null;
  },
);
